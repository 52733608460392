import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service';

@Injectable({
  providedIn: 'root'
})
export class PublicAuthGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (this.authenticationService.isUserLoggedIn() && this.router.routerState.snapshot.url.indexOf('oauth/callback') === -1 && this.router.routerState.snapshot.url.indexOf('oauth/auth-flows') === -1 && state.url.indexOf('account-setup') === -1) {
        this.router.navigate(['/secure/dashboard/customer']);
      }
      // not logged in so redirect to login page with the return url and return false
      // this.router.navigate(['/public/login'], { queryParams: { returnUrl: state.url } });
      // Access token is not available in url or session storage. not logged in so redirect to login page
      return this.router.routerState.snapshot.url.indexOf('oauth/callback') === -1 && this.router.routerState.snapshot.url.indexOf('oauth/auth-flows') === -1 && state.url.indexOf('account-setup') === -1 ? true : state.url.indexOf('account-setup') > -1 ? true : false;
  }
  
}
